import { useLoginWithRedirect } from '@frontegg/nextjs';
import * as Auth from '@pkg/auth';
import Sdk from '@pkg/sdk';
import AppLoading from '@/components/AppLoading';
import NotAuthorized from '@/components/NotAuthorized';

export default function withAuth(Component, preventRoleOnly = false) {
  function WrapperComponent(props) {
    const { me, organisation, isLoading, isAuthenticated, hasLimitedAccess } =
      Auth.useStore();
    const loginWithRedirect = useLoginWithRedirect();
    const isRoleOnly = me?.is_role_only ?? true;

    if (isLoading) {
      return <AppLoading />;
    }

    if (preventRoleOnly && isRoleOnly && organisation?.is_restricted) {
      return <NotAuthorized />;
    }

    if (!isAuthenticated || hasLimitedAccess) {
      localStorage.setItem('_REDIRECT_AFTER_LOGIN_', Sdk.fullPath());
      loginWithRedirect();
      return null;
    }

    return <Component {...props} />;
  }

  return WrapperComponent;
}
