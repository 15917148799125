import { DesignEntity } from '@/lib/enums';

export default function isEmptyState({ designs, supporterMap, user }) {
  if (!designs?.size) {
    return true;
  }

  let isEmpty = true;

  Array.from(designs.values()).forEach((designData) => {
    if (!isEmpty) {
      return;
    }

    const { snapshotEntityMap } = designData;

    const role = Array.from(
      snapshotEntityMap.get(DesignEntity.ROLE).values()
    ).find((role) => role.user_uuid === user.uuid);

    if (role) {
      isEmpty = false;
      return;
    }

    const group = Array.from(
      snapshotEntityMap.get(DesignEntity.GROUP).values()
    ).find((group) => {
      const isSupporter = supporterMap?.get(group.uuid)?.has(user.uuid);
      return isSupporter || group.lead_uuid === user.uuid;
    });

    if (group || role) {
      isEmpty = false;
    }
  });

  return isEmpty;
}
