import { LinkButton } from '@/atoms/buttons';
import Stack from '@mui/material/Stack';
import NotFound from '../NotFound';

const NotAuthorized = () => (
  <NotFound
    title="Nothing to see here."
    message={
      <span>
        Sorry, the page you have requested you are not authorised to see.
        <br />
        Please contact support if this is incorrect.
      </span>
    }
  >
    <Stack direction="row" justifyContent="center" mt={2}>
      <LinkButton size="large" path="/" label="Go back to your dashboard" />
    </Stack>
  </NotFound>
);

export default NotAuthorized;
