import App from '@/layouts/App';
import { RestrictedDashboardLayout } from '@/layouts/Dashboard';
import DesignsProvider from '@/shared/providers/DesignsProvider';
import FlowsProvider from '@/shared/providers/FlowsProvider';
import * as Auth from '@pkg/auth';
import hasRoleFor from '@pkg/entities/people/access/hasRoleFor';
import withAuth from '@/lib/auth/withAuth';
import { OrganisationRole } from '@/lib/enums';
import DashboardContainer from '@/components/DashboardContainer/DashboardContainer';

const DashboardPage = () => {
  const me = Auth.useStore((state) => state.me);
  const organisation = Auth.useStore((state) => state.organisation);

  const isAdmin = hasRoleFor(me, OrganisationRole.ADMIN, organisation);
  const isRestricted = !isAdmin && organisation.is_restricted;

  return (
    <App
      fullWidth
      pageId="dashboard"
      metaTitle="Dashboard"
      heading="My dashboard"
      noHeader={isRestricted}
      breadcrumbs={{
        crumbs: [{ label: 'Dashboard', pathname: '/dashboard' }],
        current: 'Current',
      }}
    >
      <FlowsProvider>
        {isRestricted && (
          <DesignsProvider>
            <RestrictedDashboardLayout />
          </DesignsProvider>
        )}
        {!isRestricted && <DashboardContainer />}
      </FlowsProvider>
    </App>
  );
};

export default withAuth(DashboardPage);
