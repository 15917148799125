import { create } from 'zustand';

const DEFAULT_STATE = Object.freeze({ current: null, data: {} });

const useContextStore = create((set) => ({
  bar: DEFAULT_STATE,
  overlay: DEFAULT_STATE,
  setBar: (bar) => {
    console.debug('ContextStore.set.bar', bar);
    return set({ bar: { ...DEFAULT_STATE, ...bar } });
  },
  setOverlay: (overlay) => {
    console.debug('ContextStore.set.overlay', overlay);
    return set({ overlay: { ...DEFAULT_STATE, ...overlay } });
  },
  resetBar: () => {
    console.debug('ContextStore.reset.bar');
    return set({ bar: { ...DEFAULT_STATE } });
  },
  resetOverlay: () => {
    console.debug('ContextStore.reset.overlay');
    return set({ overlay: { ...DEFAULT_STATE } });
  },
  reset: () => {
    console.debug('ContextStore.reset');
    return set({
      bar: DEFAULT_STATE,
      overlay: DEFAULT_STATE,
    });
  },
}));

export default useContextStore;
