import { DashboardLayout } from '@/layouts/Dashboard';
import {
  DesignsProvider,
  ScenarioProvider,
  TagProvider,
} from '@/shared/providers';

const DashboardContainer = () => {
  return (
    <DesignsProvider>
      <TagProvider>
        <ScenarioProvider>
          <DashboardLayout />
        </ScenarioProvider>
      </TagProvider>
    </DesignsProvider>
  );
};

export default DashboardContainer;
