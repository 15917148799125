import PropTypes from 'prop-types';
import { memo } from 'react';
import { useUnmount } from 'react-use';
import { ContextMode } from './enums';
import BarLayout from './layouts/Bar';
import OverlayLayout from './layouts/Overlay';
import useContextStore from './useContextStore';

const ContextBarContainer = ({ registry }) => {
  const { bar, overlay, reset } = useContextStore();
  useUnmount(reset);

  const Bar = registry[bar.current];
  const hasBar = Boolean(Bar);
  const barKey = `${bar.current}:${bar.data.uuid}`;

  const Overlay = registry[overlay.current];
  const hasOverlay = Boolean(Overlay);
  const overlayKey = `${overlay.current}:${overlay.data.uuid}`;

  return (
    <>
      <BarLayout isOpen={hasBar}>
        {hasBar && <Bar key={barKey} mode={ContextMode.BAR} />}
      </BarLayout>
      {hasOverlay && (
        <OverlayLayout isOpen={hasOverlay} isBarOpen={hasBar}>
          {hasOverlay && (
            <Overlay key={overlayKey} mode={ContextMode.OVERLAY} />
          )}
        </OverlayLayout>
      )}
    </>
  );
};

ContextBarContainer.propTypes = {
  registry: PropTypes.objectOf(PropTypes.elementType).isRequired,
};

export default memo(ContextBarContainer);
