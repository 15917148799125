import Skeleton from '@mui/material/Skeleton';

const SkeletonItem = ({ height = 70 }) => {
  return (
    <Skeleton
      variant="rounded"
      sx={{
        transform: 'scale(1)',
        borderRadius: '8px',
        height: height,
      }}
    />
  );
};

export default SkeletonItem;
