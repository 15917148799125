import { styled } from '@mui/material/styles';
import excludeProps from '@/lib/theme/excludeProps';
import { color } from '@/lib/theme/tokens';

export default styled('div', {
  shouldForwardProp: excludeProps(['isOpen']),
})(({ isOpen }) => ({
  background: color.core.white,
  boxShadow: `-1px 0 0 ${color.primary.grey[200]}`,
  height: '100%',
  position: 'relative',
  transition: 'width 0.2s',
  width: isOpen ? 352 : 0,
  zIndex: 2,
}));
