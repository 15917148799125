import {
  AggregateActivitiesCard,
  EmptyDashboardCard,
  EntityMetricCards,
  EntityScoreCard,
  FlowListCard,
  Matrix,
  MatrixType,
  QuadrantActivitiesCard,
  QuickLinkCards,
  ScenarioCard,
  ScenarioListCard,
  TagListCard,
  TeamListCard,
  TeamMemberListCard,
} from '@/organisms/cards';
import isEmptyState from '@/organisms/dashboards/utils/isEmptyState';
import { FlowStyle, FlowType } from '@/shared/enums';
import { useDesignsContext } from '@/shared/providers/DesignsProvider';
import { useFlowsContext } from '@/shared/providers/FlowsProvider';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import isTeamLead from '@/shared/utils/isTeamLead';
import env from '@beam-australia/react-env';
import Grid from '@mui/material/Grid';
import { useAccess } from '@pkg/access/organisations';
import * as Auth from '@pkg/auth';
import { DesignEntity, EntityMetric } from '@/lib/enums';
import { BeamibleInsight } from '@/lib/enums';
import isSupporter from '@/components/DashboardContainer/utils/isSupporter';

const Dashboard = () => {
  const { scenario } = useScenarioContext();
  const designs = useDesignsContext();
  const { me } = Auth.useStore();
  const entityType = scenario?.entity?.is_manager
    ? DesignEntity.MANAGER
    : scenario?.entity?.__type;
  const teamLead = isTeamLead(scenario?.entity, me.uuid);
  const supporter = isSupporter(
    scenario?.entity,
    designs?.supporterMap,
    me.uuid
  );
  const access = useAccess();
  const canManage = access.model.manage;
  const showScenarios =
    !scenario?.details?.isScenario &&
    scenario?.entity?.__type === DesignEntity.ROLE;
  const { flows } = useFlowsContext();
  const roleFlows = flows
    .get(FlowType.ROLE_WIZARD)
    ?.get(scenario?.entity?.uuid)
    ?.get(FlowStyle.PULSE);

  const isOrganisationView =
    scenario?.entity?.__type === DesignEntity.ORGANISATION;
  const isProd = env('PLATFORM_ENV') === 'production';

  const showScores =
    !isProd && (teamLead || supporter || (isOrganisationView && canManage));

  const showAlignment =
    entityType !== DesignEntity?.ROLE && (teamLead || supporter || canManage);

  const redirectToComparison =
    scenario?.entity?.is_manager || entityType === DesignEntity.ROLE;

  const showEmptyDashboard =
    isEmptyState({
      designs: designs?.designs,
      user: me,
      supporterMap: designs?.supporterMap,
    }) && !canManage;

  return (
    Boolean(scenario) && (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ScenarioCard
            key={`${scenario.entity?.uuid}`}
            allowLink
            showNestedSwitch
          />
        </Grid>
        {roleFlows?.length > 0 && (
          <Grid item xs={12}>
            <FlowListCard
              key={`${scenario.entity?.uuid}`}
              entityId={scenario?.entity?.uuid}
              flowStyle={FlowStyle.PULSE}
            />
          </Grid>
        )}
        {isOrganisationView && canManage && (
          <Grid item xs={12}>
            <EntityMetricCards
              metricList={[
                EntityMetric.GROUPS,
                EntityMetric.ROLES,
                EntityMetric.FTE,
                EntityMetric.BUDGET,
              ]}
            />
          </Grid>
        )}
        {showEmptyDashboard ? (
          <Grid item xs={12}>
            <EmptyDashboardCard />
          </Grid>
        ) : (
          <>
            {showScores && (
              <>
                <Grid item xs={6}>
                  <EntityScoreCard
                    key={`${scenario.entity?.uuid}:${scenario.details?.designId}:${BeamibleInsight.PRODUCTIVITY}`}
                    scoreType={BeamibleInsight.PRODUCTIVITY}
                  />
                </Grid>
                <Grid item xs={6}>
                  <EntityScoreCard
                    key={`${scenario.entity?.uuid}:${scenario.details?.designId}:${BeamibleInsight.WORK_HEALTH}`}
                    scoreType={BeamibleInsight.WORK_HEALTH}
                  />
                </Grid>
              </>
            )}
            {!isOrganisationView && (
              <Grid item xs={12}>
                <AggregateActivitiesCard
                  key={`${scenario.entity?.uuid}:${scenario.details?.designId}`}
                  entityType={entityType}
                />
              </Grid>
            )}
            {(!isOrganisationView || (isOrganisationView && canManage)) && (
              <>
                <Grid item xs={12}>
                  <QuadrantActivitiesCard
                    entityType={entityType}
                    id={MatrixType.PRIORITY}
                    key={`${scenario.entity?.uuid}:${scenario.details?.designId}:${MatrixType.PRIORITY}`}
                    matrix={Matrix[MatrixType.PRIORITY]}
                    title="Prioritisation Matrix"
                  />
                </Grid>
                {showAlignment && (
                  <Grid item xs={12}>
                    <QuadrantActivitiesCard
                      entityType={entityType}
                      id={MatrixType.ALIGNMENT}
                      key={`${scenario.entity?.uuid}:${scenario.details?.designId}:${MatrixType.ALIGNMENT}`}
                      matrix={Matrix[MatrixType.ALIGNMENT]}
                      title="Alignment on Priorities"
                    />
                  </Grid>
                )}
              </>
            )}
            {(!isOrganisationView || (isOrganisationView && canManage)) && (
              <Grid item xs={12}>
                <TagListCard
                  key={`${scenario.entity?.uuid}:${scenario.details?.designId}`}
                  title="Activity tags"
                />
              </Grid>
            )}
            {showScenarios && (
              <Grid item xs={12}>
                <ScenarioListCard
                  key={`${scenario.entity?.uuid}:${scenario.details?.designId}`}
                  entityType={scenario.entity?.__type}
                  redirectToComparison={redirectToComparison}
                />
              </Grid>
            )}
            {isOrganisationView && canManage && (
              <Grid item xs={12}>
                <QuickLinkCards />
              </Grid>
            )}
            {scenario?.details?.scope !== DesignEntity.ROLE && (
              <Grid item xs={12}>
                {entityType === DesignEntity.ORGANISATION ? (
                  <TeamListCard
                    allowLink
                    key={`${scenario.entity?.uuid}:${scenario.details?.designId}`}
                    title="Teams in your organisation"
                  />
                ) : (
                  <TeamMemberListCard
                    allowLink
                    key={`${scenario.entity?.uuid}:${scenario.details?.designId}`}
                    title="Members of your team"
                  />
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
    )
  );
};

export default Dashboard;
