import { Size } from '@/atoms/enums';
import { IconHeading } from '@/molecules/headings';
import { RoleCard } from '@/organisms/cards';
import { useUserRelatedEntities } from '@/shared/hooks';
import PortraitIcon from '@mui/icons-material/PortraitRounded';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';

const RoleList = ({ allowLinks, liveDesignsOnly = true, viewMode }) => {
  const { organisation } = Auth.useStore();
  const { roles } = useUserRelatedEntities({ liveDesignsOnly });
  const subtitle =
    roles?.length > 0
      ? `These are the roles you are assigned at ${organisation.name}`
      : `You haven't been assigned to any roles at ${organisation.name}`;

  return (
    <>
      <IconHeading
        color="primary"
        icon={<PortraitIcon />}
        size={Size.LARGE}
        subtitle={subtitle}
        title="Roles"
      />
      {roles?.length > 0 && (
        <Stack spacing={2} mt={3}>
          {roles.map((role) => (
            <RoleCard
              key={role.uuid}
              role={role}
              showLink={allowLinks}
              viewMode={viewMode}
            />
          ))}
        </Stack>
      )}
    </>
  );
};

export default RoleList;
