import { Size } from '@/atoms/enums';
import { IconHeading } from '@/molecules/headings';
import { TeamCard } from '@/organisms/cards';
import { useUserRelatedEntities } from '@/shared/hooks';
import PortraitIcon from '@mui/icons-material/PortraitRounded';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';

const TeamList = ({ liveDesignsOnly = true, viewMode }) => {
  const { organisation } = Auth.useStore();
  const { groups } = useUserRelatedEntities({ liveDesignsOnly });

  return (
    <>
      <IconHeading
        color="secondary"
        icon={<PortraitIcon />}
        size={Size.LARGE}
        subtitle={`These are the teams you manage or support at ${organisation.name}`}
        title="Teams"
      />
      {groups?.length > 0 && (
        <Stack spacing={2} mt={3}>
          {groups.map((group) => (
            <TeamCard group={group} key={group.uuid} viewMode={viewMode} />
          ))}
        </Stack>
      )}
    </>
  );
};

export default TeamList;
