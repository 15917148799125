import Grid from '@mui/material/Grid';
import Skeleton from './DashboardSkeletonItem';

const DashboardSkeleton = () => {
  return (
    <Grid container direction="row" spacing={2} sx={{ p: 2 }}>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton height={120} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton height={240} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton height={240} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton height={240} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Skeleton height={220} />
          </Grid>
          <Grid item>
            <Skeleton height={130} />
          </Grid>
          <Grid item>
            <Skeleton height={130} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardSkeleton;
