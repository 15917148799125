import { useEffect, useState } from 'react';
import { ScenarioNavigation } from '@/organisms/navigation';
import { useUserRelatedEntities } from '@/shared/hooks';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Box from '@/components/Box';
import { Container } from '@/components/ContextParts';

const Navigation = () => {
  const { designs, groups, roles } = useUserRelatedEntities();
  const { selectScenario, isLoading } = useScenarioContext();

  const [expanded, setExpanded] = useState(
    roles[0]?.uuid ?? groups[0]?.uuid ?? designs[0]?.design?.uuid
  );

  const handleClick = (uuid) => {
    setExpanded(uuid);
  };

  useEffect(() => {
    if (roles[0]) {
      const firstScenario = Array.from(roles[0].__scenarios.values())[0];
      selectScenario(roles[0], firstScenario.__designId);
      return;
    }

    if (groups[0]) {
      const firstScenario = Array.from(groups[0].__scenarios.values())[0];
      selectScenario(groups[0], firstScenario.__designId);
      return;
    }

    if (designs[0]) {
      const firstScenario = Array.from(designs[0].__scenarios.values())[0];
      selectScenario(designs[0], firstScenario.__designId);
      return;
    }
  }, [roles, groups, designs]);

  return (
    !isLoading && (
      <Container sx={{ p: 1, pb: 4 }}>
        {roles.length > 0 && (
          <Box>
            {roles.map((entity) => (
              <Box key={`${entity.uuid}:${entity.__designId}`}>
                <ScenarioNavigation
                  entity={entity}
                  expanded={expanded === entity.uuid}
                  onClick={handleClick}
                />
              </Box>
            ))}
          </Box>
        )}
        {groups.length > 0 && (
          <Box>
            {groups.map((entity) => (
              <Box key={`${entity.uuid}:${entity.__designId}`}>
                <ScenarioNavigation
                  entity={entity}
                  expanded={expanded === entity.uuid}
                  onClick={handleClick}
                />
              </Box>
            ))}
          </Box>
        )}
        {designs.length > 0 && (
          <Box>
            {designs.map((entity) => (
              <Box key={`${entity.__designId}`}>
                <ScenarioNavigation
                  entity={entity}
                  expanded={expanded === entity.design?.uuid}
                  onClick={handleClick}
                />
              </Box>
            ))}
          </Box>
        )}
      </Container>
    )
  );
};

export default Navigation;
